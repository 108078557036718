<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Deactivate Account
        </h3>
      </div>
      <div class="card-toolbar">
        <button
          type="submit"
          class="btn btn-danger mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          Deactivate
        </button>
        <button type="reset" class="btn btn-secondary" @click="cancel()">
          Cancel
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form" id="kt_deactivate_form">
      <div class="card-body">
        <div
          :class="`alert alert-${message.color}`"
          role="alert"
          v-if="message.show && message.text"
        >
          {{ message.text }}
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert"
            >Your Password</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              type="password"
              class="form-control form-control-lg form-control-solid mb-2"
              value=""
              placeholder="Your password"
              name="current_password"
              ref="current_password"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert"
            >Type "i want deactivate"</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              type="text"
              class="form-control form-control-lg form-control-solid"
              value=""
              placeholder="Type 'i want deactivate'"
              name="verify"
              ref="verify"
            />
          </div>
        </div>
      </div>
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import KTUtil from "@/assets/js/components/util";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import Swal from "sweetalert2";
import { DEACTIVATE_ACCOUNT, LOGOUT } from "@/core/services/store/auth.module";

export default {
  name: "DeactivateAccount",
  data() {
    return {
      password: "",
      status: "",
      valid: true
    };
  },
  mounted() {
    const password_change_form = KTUtil.getById("kt_deactivate_form");

    this.fv = formValidation(password_change_form, {
      fields: {
        current_password: {
          validators: {
            notEmpty: {
              message: "Current password is required"
            }
          }
        },
        verify: {
          validators: {
            notEmpty: {
              message: "Confirm verify is required"
            },
            identical: {
              compare: "i want deactivate",
              message: "The verify is not same"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap(),
        submitButton: new SubmitButton()
      }
    });
  },
  methods: {
    save() {
      this.fv.validate();

      this.fv.on("core.form.valid", () => {
        var current_password = this.$refs.current_password.value;
        var verify = this.$refs.verify.value;

        // send update request
        this.$store
          .dispatch(DEACTIVATE_ACCOUNT, { current_password, verify })
          .then(data => {
            if (data.err === "0") {
              this.$store
                .dispatch(LOGOUT)
                .then(() => this.$router.push({ name: "login" }));
            }
          });
      });

      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary"
        });
      });
    },
    cancel() {
      this.fv.resetForm();
      this.$refs.current_password.value = "";
      this.$refs.verify.value = "";
    }
  },
  computed: {
    ...mapState({
      message: state => state.message.message
    }),
    ...mapGetters(["currentUser"])
  }
};
</script>
